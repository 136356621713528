<template>
  <section>
    <div class="product-list-switch">
      <div class="product-list-switch__top">
        <button @click="() => currentListItems = list1Items" :class="{selected: currentListItems === list1Items, 'h5': true}">
          {{ item.list1Text }}
        </button>
        <button @click="() => currentListItems = list2Items" :class="{selected: currentListItems === list2Items, 'h5': true}">
          {{ item.list2Text }}
        </button>
      </div>
      <div>
        <ul class="product-list-switch__products">
          <li v-for="(itemData,i) in currentListItems" :key="i">
            <ProductItem :product="itemData.product" :full-width="fullWidthPorductItem"></ProductItem>
          </li>
        </ul>
      </div>
      <div class="product-list-switch__bottom">
        <a :href="item.ctaLink" class="btn -stroke">
          {{ item.ctaText }}
        </a>
      </div>
    </div>
  </section>
</template>

<script>
import ProductItem from './ProductItem.vue'

export default {
  props: ['item', 'items', 'pagePosition', 'siblings-components'],
  name: 'ProductListSwitch',
  components: {
    ProductItem
  },
  data() {
    const list1Name = 'LIST_1'
    const list2Name = 'LIST_2'
    const list1Items = this.items.filter(itemData => itemData.referenceList === list1Name)
    const list2Items = this.items.filter(itemData => itemData.referenceList === list2Name)

    return {
      list1Items,
      list2Items,
      currentListItems: list1Items
    }
  },
  computed: {
    fullWidthPorductItem() {
      return this.currentListItems.length >= 4
    }
  }
}
</script>

<style lang="scss">
  @import "~theme/css/core";

  .product-list-switch {
    row-gap: 4rem;
    display: flex;
    flex-direction: column;
    margin: 4rem 6rem;

    @include bp(max md) {
      margin: 4rem 0rem;
      row-gap: 2rem;
    }

    &__top {
      display: flex;
      justify-content: center;
      column-gap: 2rem;

      button {
        border: none;
        background: none;

        &.selected{
          color: $link-color;
          text-decoration: underline;
        }
      }
    }

    &__products {
      list-style-type: none;
      display: flex;
      justify-content: center;
      gap: 2rem;
      padding: 0;

      @include bp(max md) {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }

      > li {
        // 100% - gaps divided by number of columns
        flex-basis: calc((100% - 2rem* 3) / 4);

        .product .tile-information .title {
          font-size: calc(max(min(20%, #{em(12)}), 1em));
        }
      }
    }

    &__bottom {
      display: flex;
      justify-content: center;
    }
  }
</style>
